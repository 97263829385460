import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import Hero from './hero'

const shouldForwardProp = (prop) => !['noParallax'].includes(prop)

const HeroStyled = styled(Hero, { shouldForwardProp })`
	height: 100%;
	align-items: center;
	.overlay-container {
		max-width: 384px;
		position: relative;
		margin: 60px 40px;
	}
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	line-height: 1.1;
	text-align: center;
	font-size: ${({ theme }) => theme.typography.pxToRem(25)};

	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 274px;
		text-align: left;
		font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	}
`
const Description = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	margin-top: 20px;
	text-align: center;
	line-height: var(--layout-line-height-medium);

	${({ theme }) => theme.breakpoints.up('md')} {
		text-align: left;
	}

	.MuiTypography-root {
		font-size: inherit;
		line-height: inherit;
	}
`
const FormWrapper = styled('div')`
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: column;
	max-width: 590px;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		margin-top: var(--spacing-6);
	}

	.MuiFormControl-root.MuiTextField-root {
		margin-right: 0;
		.MuiInputBase-root {
			height: 43px;
			padding: 0 12px;
			&:before {
				border-bottom: 1px solid ${({ theme }) => theme.palette.common.white};
			}
			&:after {
				border-bottom: 1px solid ${({ theme }) => theme.palette.common.white};
			}
			.MuiInput-input {
				color: ${({ theme }) => theme.palette.common.white};
				font-size: ${({ theme }) => theme.typography.pxToRem(12)};
				padding-right: 0px;

				&::placeholder {
					color: ${({ theme }) => theme.palette.common.white};
				}
			}

			&.Mui-error {
				&:after {
					border-bottom-color: ${({ theme }) => theme.palette.common.white};
				}

				+ .MuiFormHelperText-root {
					color: ${({ theme }) => theme.palette.common.white};
					text-decoration: underline;
					margin-top: 10px;
				}
			}
		}

		& + .MuiButtonBase-root {
			min-width: 55px;
			width: max-content;
			padding: 11px 20px;
			height: 43px;
			background-color: ${({ theme }) => theme.palette.common.white};
			border-color: ${({ theme }) => theme.palette.common.white};
			color: ${({ theme }) => theme.palette.common.black};
			text-transform: uppercase;
			${({ theme }) => theme.breakpoints.up('md')} {
				min-width: 99px;
			}
		}
	}

	.MuiFormControlLabel-root {
		color: ${({ theme }) => theme.palette.common.white};
		font-size: ${({ theme }) => theme.typography.pxToRem(10)};
		align-items: flex-start;
		margin-top: 10px;
		.MuiFormControlLabel-label {
			font-size: inherit;
			color: inherit;
			a {
				color: inherit;
				text-decoration-color: ${({ theme }) => theme.palette.common.white};
			}
			.MuiTypography-root {
				font-size: inherit;
				color: inherit;
				text-align: justify;
			}
		}
		.MuiCheckbox-root {
			color: inherit;
			svg {
				color: inherit;
				fill: ${({ theme }) => theme.palette.common.white};
				path {
					fill: inherit;
				}
			}
		}
		& + .MuiFormHelperText-root.Mui-error {
			color: ${({ theme }) => theme.palette.common.white};
			margin-top: 10px;
			margin-left: 28px;
			text-decoration: underline;
		}
	}

	.MuiIconButton-root {
		border-radius: 0px;
		position: relative;
		right: 0;
		top: 0;
		height: 44px;
	}
`

export { Title, Description, FormWrapper, HeroStyled }
